<template>
	<section class="section is-paddingless">
		<Navbar />
		<br /><br />
		<div class="container">
			<div class="row justify-content-center">
				<div class="page-body">
					<h1 class="my-5 page-title has-text-centered">Privacy Policy</h1>
					<p>
						<strong> Last updated November 21, 2022 </strong>
					</p>
					<p id="931b618f-b146-4279-ac17-c1d84bcc49fe" class="">
						This privacy notice for Morpher Labs GmbH (doing business as
						Morpher) (&quot;<strong>Morpher</strong>,&quot;
						&quot;<strong>we</strong>,&quot; &quot;<strong>us</strong>,&quot; or
						&quot;<strong>our</strong>&quot;), describes how and why we might
						collect, store, use, and/or share
						(&quot;<strong>process</strong>&quot;) your information when you use
						our services (&quot;<strong>Services</strong>&quot;), such as when
						you:
					</p>
					<ul id="0a45f102-2228-4808-9795-939702ced57d" class="bulleted-list">
						<li style="list-style-type: disc">
							Download and use our mobile application (Morpher), or any other
							application of ours that links to this privacy notice
						</li>
					</ul>
					<ul id="108020ed-d1e2-49b0-bfcb-ae3776fadc71" class="bulleted-list">
						<li style="list-style-type: disc">
							Engage with us in other related ways, including any sales,
							marketing, or events
						</li>
					</ul>
					<p id="f7177652-dcb1-4231-980b-4f1116b3f18d" class="">
						<strong>Questions or concerns? </strong>Reading this privacy notice
						will help you understand your privacy rights and choices. If you do
						not agree with our policies and practices, please do not use our
						Services. If you still have any questions or concerns, please
						contact us at privacy@morpher.com.
					</p>
					<h2 id="7eb40022-259b-47ee-bd92-d59a81274577" class="">
						<strong>Summary of Key Points</strong>
					</h2>
					<p id="4697b732-686d-419a-bd98-a45d66861ecd" class="">
						<em
							><strong
								>This summary provides key points from our privacy notice, but
								you can find out more details about any of these topics by
								clicking the link following each key point or by using our table
								of contents below to find the section you are looking for. You
								can also click </strong
							></em
						><a href="#table-of-contents"
							><em><strong>here</strong></em></a
						><em><strong> to go directly to our table of contents.</strong></em>
					</p>
					<p id="9299fdf6-1ba0-4647-b597-e3b6ffd59ec7" class="">
						<strong>What personal information do we process?</strong> When you
						visit, use, or navigate our Services, we may process personal
						information depending on how you interact with Morpher and the
						Services, the choices you make, and the products and features you
						use. Click <a href="#99deff0d-f9dd-4115-8047-336f3c8451b8">here</a
						> to learn more.
					</p>
					<p id="298d30d3-b88e-4ee8-83b8-f3c5aaa3e07c" class="">
						<strong>Do we process any sensitive personal information?</strong>
						We may process sensitive personal information when necessary with
						your consent or as otherwise permitted by applicable law. Click <a
							href="#caff2e59-5fd8-47b5-bb42-e5dfac650ec6"
							>here</a
						> to learn more.
					</p>
					<p id="89e4ac54-8cc0-4c47-b2bc-1b8361568e69" class="">
						<strong>Do we receive any information from third parties?</strong>
						We do not receive any information from third parties.
					</p>
					<p id="5f53aa9c-d7f0-4c32-b12e-20a69189958b" class="">
						<strong>How do we process your information?</strong> We process your
						information to provide, improve, and administer our Services,
						communicate with you, for security and fraud prevention, and to
						comply with law. We may also process your information for other
						purposes with your consent. We process your information only when we
						have a valid legal reason to do so. Click <a
							href="#how-do-we-process-your-information"
							>here</a
						> to learn more.
					</p>
					<p id="04b4a289-d4aa-4b40-9385-3bc7e365d34c" class="">
						<strong
							>In what situations and with which parties do we share personal
							information?</strong
						>
						We may share information in specific situations and with specific
						third parties. Click <a
							href="#when-and-with-whom-share-personal-information"
							>here</a
						> to learn more.
					</p>
					<p id="61782698-b45e-4467-a22d-0e873ba04c1b" class="">
						<strong>How do we keep your information safe?</strong> We have
						organizational and technical processes and procedures in place to
						protect your personal information. However, no electronic
						transmission over the internet or information storage technology can
						be guaranteed to be 100% secure, so we cannot promise or guarantee
						that hackers, cybercriminals, or other unauthorized third parties
						will not be able to defeat our security and improperly collect,
						access, steal, or modify your information. Click <a
							href="#how-keep-information-safe"
							>here</a
						> to learn more.
					</p>
					<p id="9ac4057b-dbab-4aa9-b02f-83247d931f0b" class="">
						<strong>What are your rights?</strong> Depending on where you are
						located geographically, the applicable privacy law may mean you have
						certain rights regarding your personal information. Click <a
							href="#what-are-your-privacy-rights"
							>here</a
						> to learn more.
					</p>
					<p id="3c46e5df-080d-4c6f-99e9-ed96f8f79c52" class="">
						<strong>How do you exercise your rights?</strong> The easiest way to
						exercise your rights is by
						<a href="mailto:contact@help.morpher.com">contacting support</a>. We
						will consider and act upon any request in accordance with applicable
						data protection laws.
					</p>
					<p id="90f49c97-b183-4596-ad4d-61da2e5c1c40" class="">
						Want to learn more about what Morpher does with any information we
						collect? Click <a href="#table-of-contents">here</a> to review the
						notice in full.
					</p>
					<h2 id="table-of-contents" class="">
						<strong>Table of Contents</strong>
					</h2>
					<nav
						id="8c55ca62-b0c6-4fc0-afdf-9266e73af3e4"
						class="block-color-gray table_of_contents"
					>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#what-information-do-we-collect"
								><strong>1. What Information Do We Collect?</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#how-do-we-process-your-information"
								><strong>2. How Do We Process Your Information?</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#what-legal-bases-process-information"
								><strong
									>3. What Legal Bases Do We Rely On To Process Your
									Information?</strong
								></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#when-and-with-whom-share-personal-information"
								><strong
									>4. When And With Whom Do We Share Your Personal
									Information?</strong
								></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#do-we-use-cookies-tracking"
								><strong
									>5. Do We Use Cookies And Other Tracking Technologies?</strong
								></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a class="table_of_contents-link" href="#how-handle-social-logins"
								><strong>6. How Do We Handle Your Social Logins?</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#how-long-keep-information"
								><strong>7. How Long Do We Keep Your Information?</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#how-keep-information-safe"
								><strong>8. How Do We Keep Your Information Safe?</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#do-we-collect-information-from-minors"
								><strong>9. Do We Collect Information From Minors?</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#what-are-your-privacy-rights"
								><strong>10. What Are Your Privacy Rights?</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#controls-for-do-not-track-features"
								><strong>11. Controls For Do-Not-Track Features</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#california-specific-rights"
								><strong
									>12. Do California Residents Have Specific Privacy
									Rights?</strong
								></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#do-we-make-updates-to-this-notice"
								><strong>13. Do We Make Updates To This Notice?</strong></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#contact-about-this-notice"
								><strong
									>14. How Can You Contact Us About This Notice?</strong
								></a
							>
						</div>
						<div class="table_of_contents-item table_of_contents-indent-0">
							<a
								class="table_of_contents-link"
								href="#review-update-delete-data-collected"
								><strong
									>15. How Can You Review, Update, Or Delete The Data We Collect
									From You?</strong
								></a
							>
						</div>
					</nav>
					<h2 id="what-information-do-we-collect" class="">
						<strong>1. What Information Do We Collect?</strong>
					</h2>
					<p id="7736c851-7f10-4888-9ee6-0c690704ebac" class="">
						<strong>Personal information you disclose to us</strong>
					</p>
					<p id="d12b1edc-49c2-4322-9593-442e53f92943" class="">
						<em><strong>In Short:</strong></em
						><em><strong> </strong></em
						><em>We collect personal information that you provide to us.</em>
					</p>
					<p id="87e53ce8-88c0-44bb-9573-0d656fb48daa" class="">
						We collect personal information that you voluntarily provide to us
						when you register on the Services, express an interest in obtaining
						information about us or our products and Services, when you
						participate in activities on the Services, or otherwise when you
						contact us.
					</p>
					<p id="99deff0d-f9dd-4115-8047-336f3c8451b8" class="">
						<strong>Personal Information Provided by You.</strong> The personal
						information that we collect depends on the context of your
						interactions with us and the Services, the choices you make, and the
						products and features you use. The personal information we collect
						may include the following:
					</p>
					<ul id="cc63fb2f-3dfa-4ee2-8580-55f7238431b2" class="bulleted-list">
						<li style="list-style-type: disc">email addresses</li>
					</ul>
					<ul id="68a7bab5-13b9-4879-ae6f-f35595853d89" class="bulleted-list">
						<li style="list-style-type: disc">names</li>
					</ul>
					<ul id="f1425f94-6377-464f-930f-4cb3eaf7dcf2" class="bulleted-list">
						<li style="list-style-type: disc">usernames</li>
					</ul>
					<ul id="f602b272-82b8-4c86-9800-5d5c97500bb2" class="bulleted-list">
						<li style="list-style-type: disc">passwords</li>
					</ul>
					<ul id="c90d822b-d7c6-4c1e-ada2-485e5b5b0734" class="bulleted-list">
						<li style="list-style-type: disc">contact preferences</li>
					</ul>
					<ul id="72aca350-c70f-48ac-8511-c6d9e723800f" class="bulleted-list">
						<li style="list-style-type: disc">
							contact or authentication data
						</li>
					</ul>
					<ul id="4c559337-2de3-40af-bea7-5f4fadb24877" class="bulleted-list">
						<li style="list-style-type: disc">billing addresses</li>
					</ul>
					<ul id="8a20858f-c440-4e81-8d82-14aa94835020" class="bulleted-list">
						<li style="list-style-type: disc">debit/credit card numbers</li>
					</ul>
					<ul id="d2edaf06-a174-4589-aec6-72b7c7036d1b" class="bulleted-list">
						<li style="list-style-type: disc">
							government issued identification
						</li>
					</ul>
					<ul id="f9410f67-ccc2-40bc-b356-72661753cdf9" class="bulleted-list">
						<li style="list-style-type: disc">ip address</li>
					</ul>
					<ul id="b973b50a-4733-4b55-8909-6facad0e205b" class="bulleted-list">
						<li style="list-style-type: disc">photos (for verification)</li>
					</ul>
					<p id="caff2e59-5fd8-47b5-bb42-e5dfac650ec6" class="">
						<strong>Sensitive Information.</strong> When necessary, with your
						consent or as otherwise permitted by applicable law, we process the
						following categories of sensitive information:
					</p>
					<ul id="93727b1d-8350-4163-a231-40af42175182" class="bulleted-list">
						<li style="list-style-type: disc">financial data</li>
					</ul>
					<ul id="49f05b74-0635-4b4d-bf67-c3ce542bb05b" class="bulleted-list">
						<li style="list-style-type: disc">biometric data</li>
					</ul>
					<ul id="caad19bf-8cfe-4e0f-9d1f-4f532c2abb94" class="bulleted-list">
						<li style="list-style-type: disc">
							social security numbers or other government identifiers
						</li>
					</ul>
					<p id="8139a1c4-8672-4c89-be0c-53d827a8a4b8" class="">
						<strong>Payment Data.</strong> We may collect data necessary to
						process your payment if you make purchases, such as your payment
						instrument number, and the security code associated with your
						payment instrument. All payment data is stored by PayPal, Wyre,
						Advcash and Coinbase Commerce. You may find their privacy notice
						link(s) here:
						<a href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full"
							>https://www.paypal.com/us/webapps/mpp/ua/privacy-full</a
						>,
						<a href="https://www.sendwyre.com/legal/privacy-policy"
							>https://www.sendwyre.com/legal/privacy-policy</a
						>,
						<a href="https://advcash.com/en/privacy-notice"
							>https://advcash.com/en/privacy-notice</a
						>
						and
						<a href="https://commerce.coinbase.com/legal/privacy-policy/"
							>https://commerce.coinbase.com/legal/privacy-policy/</a
						>.
					</p>
					<p id="cd8c1e0e-d8f1-4647-8814-04c5b6e6e64a" class="">
						<strong>Social Media Login Data. </strong>We may provide you with
						the option to register with us using your existing social media
						account details, like your Facebook, Twitter, or other social media
						account. If you choose to register in this way, we will collect the
						information described in the section called &quot;<a
							href="#how-handle-social-logins"
							>How Do We Handle Your Social Logins?</a
						>&quot; below.
					</p>
					<p id="a306072b-224a-4e6c-abc2-386602d68a59" class="">
						<strong>Application Data.</strong>
					</p>
					<p id="07948c1f-ed1b-4aec-aa55-7af1cb8ad3cb" class="">
						If you use our application(s), we also may collect the following
						information if you choose to provide us with access or permission:
					</p>
					<ul id="26f0255e-6d09-4221-9f1f-e22f8dc4d471" class="bulleted-list">
						<li style="list-style-type: disc">
							<em>Mobile Device Access.</em> We may request access or permission
							to certain features from your mobile device, including your mobile
							device&#x27;s camera, social media accounts, and other features.
							If you wish to change our access or permissions, you may do so in
							your device&#x27;s settings.
						</li>
					</ul>
					<ul id="4b1309fa-a566-4c7c-bc33-6b1906fe81dc" class="bulleted-list">
						<li style="list-style-type: disc">
							<em>Mobile Device Data.</em> We automatically collect device
							information (such as your mobile device ID, model, and
							manufacturer), operating system, version information and system
							configuration information, device and application identification
							numbers, browser type and version, hardware model Internet service
							provider and/or mobile carrier, and Internet Protocol (IP) address
							(or proxy server). If you are using our application(s), we may
							also collect information about the phone network associated with
							your mobile device, your mobile device’s operating system or
							platform, the type of mobile device you use, your mobile device’s
							unique device ID, and information about the features of our
							application(s) you accessed.
						</li>
					</ul>
					<ul id="fb74ed6b-81ea-4b91-9a98-472744290449" class="bulleted-list">
						<li style="list-style-type: disc">
							<em>Push Notifications.</em> We may request to send you push
							notifications regarding your account or certain features of the
							application(s). If you wish to opt out from receiving these types
							of communications, you may turn them off in your device&#x27;s
							settings.
						</li>
					</ul>
					<p id="5761444e-baf5-43d1-a66e-883413e05a22" class="">
						This information is primarily needed to maintain the security and
						operation of our application(s), for troubleshooting, and for our
						internal analytics and reporting purposes.
					</p>
					<p id="9ea972be-2809-4355-afad-71eed6f53d23" class="">
						All personal information that you provide to us must be true,
						complete, and accurate, and you must notify us of any changes to
						such personal information.
					</p>
					<p id="cb9a9887-2364-4f01-bfde-922c903115db" class="">
						<strong>Information automatically collected</strong>
					</p>
					<p id="62f1d6dd-c5b7-44af-87f3-08b1251fc902" class="">
						<em><strong>In Short:</strong></em
						><em><strong> </strong></em
						><em
							>Some information — such as your Internet Protocol (IP) address
							and/or browser and device characteristics — is collected
							automatically when you visit our Services.</em
						>
					</p>
					<p id="e06b4eaf-d7b0-4d6b-90ab-b0bbc6b270f2" class="">
						We automatically collect certain information when you visit, use, or
						navigate the Services. This information does not reveal your
						specific identity (like your name or contact information) but may
						include device and usage information, such as your IP address,
						browser and device characteristics, operating system, language
						preferences, referring URLs, device name, country, location,
						information about how and when you use our Services, and other
						technical information. This information is primarily needed to
						maintain the security and operation of our Services, and for our
						internal analytics and reporting purposes.
					</p>
					<p id="b1e70d49-53c2-4c3c-8aa8-5e1382ea3ca2" class="">
						Like many businesses, we also collect information through cookies
						and similar technologies. You can find out more about this in our
						Cookie Notice:
						<a href="https://www.morpher.com/cookie-policy"
							>https://www.morpher.com/cookie-policy</a
						>.
					</p>
					<p id="0e4c118c-faec-4572-8980-67d53d079484" class="">
						The information we collect includes:
					</p>
					<ul id="afb2ba40-3bdf-4986-b1a1-9c8ba27e7d99" class="bulleted-list">
						<li style="list-style-type: disc">
							<em>Log and Usage Data.</em> Log and usage data is
							service-related, diagnostic, usage, and performance information
							our servers automatically collect when you access or use our
							Services and which we record in log files. Depending on how you
							interact with us, this log data may include your IP address,
							device information, browser type, and settings and information
							about your activity in the Services (such as the date/time stamps
							associated with your usage, pages and files viewed, searches, and
							other actions you take such as which features you use), device
							event information (such as system activity, error reports
							(sometimes called &quot;crash dumps&quot;), and hardware
							settings).
						</li>
					</ul>
					<ul id="3a42d41f-c343-4c45-a346-e57c61507135" class="bulleted-list">
						<li style="list-style-type: disc">
							<em>Device Data.</em> We collect device data such as information
							about your computer, phone, tablet, or other device you use to
							access the Services. Depending on the device used, this device
							data may include information such as your IP address (or proxy
							server), device and application identification numbers, location,
							browser type, hardware model, Internet service provider and/or
							mobile carrier, operating system, and system configuration
							information.
						</li>
					</ul>
					<ul id="549cb2d9-670d-4e47-8663-9977566dd835" class="bulleted-list">
						<li style="list-style-type: disc">
							<em>Location Data.</em> We collect location data such as
							information about your device&#x27;s location, which can be either
							precise or imprecise. How much information we collect depends on
							the type and settings of the device you use to access the
							Services. For example, we may use GPS and other technologies to
							collect geolocation data that tells us your current location
							(based on your IP address). You can opt out of allowing us to
							collect this information either by refusing access to the
							information or by disabling your Location setting on your device.
							However, if you choose to opt out, you may not be able to use
							certain aspects of the Services.
						</li>
					</ul>
					<h2 id="how-do-we-process-your-information" class="">
						<strong>2. How Do We Process Your Information?</strong>
					</h2>
					<p id="afff105d-148b-40b8-bb3e-1ca99e46ff9c" class="">
						<em><strong>In Short: </strong></em
						><em
							>We process your information to provide, improve, and administer
							our Services, communicate with you, for security and fraud
							prevention, and to comply with law. We may also process your
							information for other purposes with your consent.</em
						>
					</p>
					<p id="bb2428de-dd02-4d4e-a885-6ec0a3c06bc1" class="">
						<strong
							>We process your personal information for a variety of reasons,
							depending on how you interact with our Services,
							including:</strong
						>
					</p>
					<ul id="270f6ede-1366-4335-826d-0a1594422bf5" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong
								>To facilitate account creation and authentication and otherwise
								manage user accounts. </strong
							>We may process your information so you can create and log in to
							your account, as well as keep your account in working order.
						</li>
					</ul>
					<ul id="b5306317-3320-402d-b469-9a4f4418db13" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong
								>To deliver and facilitate delivery of services to the
								user. </strong
							>We may process your information to provide you with the requested
							service.
						</li>
					</ul>
					<ul id="8bf8f2fb-1307-4adc-ac76-eccfc0b3d0db" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong
								>To respond to user inquiries/offer support to users. </strong
							>We may process your information to respond to your inquiries and
							solve any potential issues you might have with the requested
							service.
						</li>
					</ul>
					<ul id="d8835358-d4eb-47cc-80f4-51de951f9252" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>To send administrative information to you. </strong>We may
							process your information to send you details about our products
							and services, changes to our terms and policies, and other similar
							information.
						</li>
					</ul>
					<ul id="db58a21b-5b96-4fc1-aa12-9012932b82db" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>To request feedback. </strong>We may process your
							information when necessary to request feedback and to contact you
							about your use of our Services.
						</li>
					</ul>
					<ul id="5f6aa38f-b410-4685-877b-1e4fb6f9457d" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong
								>To send you marketing and promotional communications. </strong
							>We may process the personal information you send to us for our
							marketing purposes, if this is in accordance with your marketing
							preferences. You can opt out of our marketing emails at any time.
							For more information, see &quot;<a
								href="#what-are-your-privacy-rights"
								>What Are Your Privacy Rights?</a
							>&quot; below).
						</li>
					</ul>
					<ul id="ae871a00-bae9-4abd-809d-54fc2362dc08" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>To protect our Services.</strong> We may process your
							information as part of our efforts to keep our Services safe and
							secure, including fraud monitoring and prevention.
						</li>
					</ul>
					<ul id="73c03d59-1c1a-4b15-aa45-e7b40feecacd" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>To identify usage trends.</strong> We may process
							information about how you use our Services to better understand
							how they are being used so we can improve them.
						</li>
					</ul>
					<ul id="b0dcc3d2-4ef8-40ba-9250-9d6544663d1c" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong
								>To save or protect an individual&#x27;s vital interest.</strong
							>
							We may process your information when necessary to save or protect
							an individual’s vital interest, such as to prevent harm.
						</li>
					</ul>
					<h2 id="what-legal-bases-process-information" class="">
						<strong
							>3. What Legal Bases Do We Rely On To Process Your
							Information?</strong
						>
					</h2>
					<p id="5d394d4b-7a7d-4e51-9b21-f47ff21ac7d2" class="">
						<em><strong>In Short: </strong></em
						><em
							>We only process your personal information when we believe it is
							necessary and we have a valid legal reason (i.e., legal basis) to
							do so under applicable law, like with your consent, to comply with
							laws, to provide you with services to enter into or fulfill our
							contractual obligations, to protect your rights, or to fulfill our
							legitimate business interests.</em
						>
					</p>
					<p id="fb0e1ff7-f26b-4e56-acb7-348d35efa52d" class="">
						<span style="border-bottom: 0.05em solid"
							><em
								><strong
									>If you are located in the EU or UK, this section applies to
									you.</strong
								></em
							></span
						>
					</p>
					<p id="1e37ef75-5ebf-4a02-b97c-c9675f415a71" class="">
						The General Data Protection Regulation (GDPR) and UK GDPR require us
						to explain the valid legal bases we rely on in order to process your
						personal information. As such, we may rely on the following legal
						bases to process your personal information:
					</p>
					<ul id="5af0017f-8f72-4ccf-9b37-6337009aa5bf" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Consent. </strong>We may process your information if you
							have given us permission (i.e., consent) to use your personal
							information for a specific purpose. You can withdraw your consent
							at any time. Click <a href="#db18c62c-77d1-476a-8115-dfef04fe5067"
								>here</a
							> to learn more.
						</li>
					</ul>
					<ul id="d3eb3471-aebf-4689-8ade-b01e25de0fb7" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Performance of a Contract.</strong> We may process your
							personal information when we believe it is necessary to fulfill
							our contractual obligations to you, including providing our
							Services or at your request prior to entering into a contract with
							you.
						</li>
					</ul>
					<ul id="8f74b9d6-737e-4445-8454-0b02ff28b93f" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Legitimate Interests.</strong> We may process your
							information when we believe it is reasonably necessary to achieve
							our legitimate business interests and those interests do not
							outweigh your interests and fundamental rights and freedoms. For
							example, we may process your personal information for some of the
							purposes described in order to:
						</li>
					</ul>
					<ul id="de5bbb76-bfa1-4408-b6e1-98e548d36df5" class="bulleted-list">
						<li style="list-style-type: disc">
							Send users information about special offers and discounts on our
							products and services
						</li>
					</ul>
					<ul id="d7498089-c735-47e9-a326-2d98c565daf1" class="bulleted-list">
						<li style="list-style-type: disc">
							Analyze how our Services are used so we can improve them to engage
							and retain users
						</li>
					</ul>
					<ul id="73fbd23c-0428-4ab9-ab99-24ee15ee9b9d" class="bulleted-list">
						<li style="list-style-type: disc">
							Diagnose problems and/or prevent fraudulent activities
						</li>
					</ul>
					<ul id="95f7e591-7101-45a4-a8c8-40fe45ba4787" class="bulleted-list">
						<li style="list-style-type: disc">
							Understand how our users use our products and services so we can
							improve user experience
						</li>
					</ul>
					<ul id="5773cd45-0403-4125-819e-1e749eed9968" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Legal Obligations.</strong> We may process your
							information where we believe it is necessary for compliance with
							our legal obligations, such as to cooperate with a law enforcement
							body or regulatory agency, exercise or defend our legal rights, or
							disclose your information as evidence in litigation in which we
							are involved.
						</li>
					</ul>
					<ul id="d37a7d16-ec4a-4d45-8bb7-1d04a1ecbce8" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Vital Interests.</strong> We may process your information
							where we believe it is necessary to protect your vital interests
							or the vital interests of a third party, such as situations
							involving potential threats to the safety of any person.
						</li>
					</ul>
					<p id="eb9eab2b-18a8-40db-9188-12de91995a2e" class="">
						In legal terms, we are generally the &quot;data controller&quot;
						under European data protection laws of the personal information
						described in this privacy notice, since we determine the means
						and/or purposes of the data processing we perform. This privacy
						notice does not apply to the personal information we process as a
						&quot;data processor&quot; on behalf of our customers. In those
						situations, the customer that we provide services to and with whom
						we have entered into a data processing agreement is the &quot;data
						controller&quot; responsible for your personal information, and we
						merely process your information on their behalf in accordance with
						your instructions. If you want to know more about our
						customers&#x27; privacy practices, you should read their privacy
						policies and direct any questions you have to them.
					</p>
					<p id="227be8ac-8727-42ec-8989-c0b710193e2b" class="">
						<span style="border-bottom: 0.05em solid"
							><em
								><strong
									>If you are located in Canada, this section applies to
									you.</strong
								></em
							></span
						>
					</p>
					<p id="7c636c32-1fed-4692-acf3-d49ef44075eb" class="">
						We may process your information if you have given us specific
						permission (i.e., express consent) to use your personal information
						for a specific purpose, or in situations where your permission can
						be inferred (i.e., implied consent). You can withdraw your consent
						at any time. Click <a href="#db18c62c-77d1-476a-8115-dfef04fe5067"
							>here</a
						> to learn more.
					</p>
					<p id="b1b5889a-044f-47be-b9b5-1bc48fa7344d" class="">
						In some exceptional cases, we may be legally permitted under
						applicable law to process your information without your consent,
						including, for example:
					</p>
					<ul id="5a402e61-2d30-4fb6-8217-1b8b667f3254" class="bulleted-list">
						<li style="list-style-type: disc">
							If collection is clearly in the interests of an individual and
							consent cannot be obtained in a timely way
						</li>
					</ul>
					<ul id="22b9e0cb-4a0b-45b0-968d-99c09d1fd679" class="bulleted-list">
						<li style="list-style-type: disc">
							For investigations and fraud detection and prevention
						</li>
					</ul>
					<ul id="54b29788-da65-46b5-8537-59d6e608f45e" class="bulleted-list">
						<li style="list-style-type: disc">
							For business transactions provided certain conditions are met
						</li>
					</ul>
					<ul id="e8cfdbb9-dcfc-4d11-b7f4-91cc3cfba2ee" class="bulleted-list">
						<li style="list-style-type: disc">
							If it is contained in a witness statement and the collection is
							necessary to assess, process, or settle an insurance claim
						</li>
					</ul>
					<ul id="4384fc2e-e558-45e3-98b1-25d8452d58cc" class="bulleted-list">
						<li style="list-style-type: disc">
							For identifying injured, ill, or deceased persons and
							communicating with next of kin
						</li>
					</ul>
					<ul id="ccdf0b91-0fa4-4310-8202-5954d289b75c" class="bulleted-list">
						<li style="list-style-type: disc">
							If we have reasonable grounds to believe an individual has been,
							is, or may be victim of financial abuse
						</li>
					</ul>
					<ul id="52b06edf-48e4-4bdc-8f4c-6a3c8f244bb0" class="bulleted-list">
						<li style="list-style-type: disc">
							If it is reasonable to expect collection and use with consent
							would compromise the availability or the accuracy of the
							information and the collection is reasonable for purposes related
							to investigating a breach of an agreement or a contravention of
							the laws of Canada or a province
						</li>
					</ul>
					<ul id="be830a44-998b-422c-b04e-d8f3ad650d11" class="bulleted-list">
						<li style="list-style-type: disc">
							If disclosure is required to comply with a subpoena, warrant,
							court order, or rules of the court relating to the production of
							records
						</li>
					</ul>
					<ul id="515563e7-8c25-4e72-8f98-a37c05e15494" class="bulleted-list">
						<li style="list-style-type: disc">
							If it was produced by an individual in the course of their
							employment, business, or profession and the collection is
							consistent with the purposes for which the information was
							produced
						</li>
					</ul>
					<ul id="21e96634-b5f4-4d5c-a60f-db113a788b9a" class="bulleted-list">
						<li style="list-style-type: disc">
							If the collection is solely for journalistic, artistic, or
							literary purposes
						</li>
					</ul>
					<ul id="bb656f86-4c57-4a6e-9bd9-8aef92085d00" class="bulleted-list">
						<li style="list-style-type: disc">
							If the information is publicly available and is specified by the
							regulations
						</li>
					</ul>
					<h2 id="when-and-with-whom-share-personal-information" class="">
						<strong
							>4. When And With Whom Do We Share Your Personal
							Information?</strong
						>
					</h2>
					<p id="90d3d26b-3b27-4fd3-9fe0-828ec00687f5" class="">
						<em><strong>In Short:</strong></em
						><em
							> We may share information in specific situations described in
							this section and/or with the following third parties.</em
						>
					</p>
					<p id="a8ba3cca-0e19-44a5-9984-c95e87fc2acd" class="">
						<strong
							>Vendors, Consultants, and Other Third-Party Service
							Providers.</strong
						>
						We may share your data with third-party vendors, service providers,
						contractors, or agents (&quot;<strong>third parties</strong>&quot;)
						who perform services for us or on our behalf and require access to
						such information to do that work. We have contracts in place with
						our third parties, which are designed to help safeguard your
						personal information. This means that they cannot do anything with
						your personal information unless we have instructed them to do it.
						They will also not share your personal information with any
						organization apart from us. They also commit to protect the data
						they hold on our behalf and to retain it for the period we instruct.
						The third parties we may share personal information with are as
						follows:
					</p>
					<ul id="94c86540-559a-46bf-890b-9d79dd956bf6" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong
								>Allow Users to Connect to Their Third-Party Accounts</strong
							>
							<p id="1e8bf6e6-b84c-4e1e-844a-5487d4162107" class="">
								Facebook account, Google account, Apple Account and VK Account
							</p>
						</li>
					</ul>
					<ul id="c360970c-61e5-4875-b007-517bf9692469" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Communicate and Chat with Users</strong>
							<p id="07cb90c0-97b6-496f-abca-c07cb41b422c" class="">Crisp</p>
						</li>
					</ul>
					<ul id="890f2749-13e4-422f-b5da-2f6ef6833da7" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Invoice and Billing</strong>
							<p id="7942b1e5-8d81-4f17-ae65-6ce0c038ae18" class="">
								PayPal, Wyre, Advcash and Coinbase
							</p>
						</li>
					</ul>
					<ul id="45304fb2-64af-4501-a1f9-0a9cc81595a0" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>User Account Registration and Authentication</strong>
							<p id="bcd1d386-b027-4c77-92ec-ed387376a9a5" class="">
								Google Sign-In and Apple Sign-In
							</p>
						</li>
					</ul>
					<ul id="ac455f33-10ad-4c86-ab41-c8d861d8c6fa" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Web and Mobile Analytics</strong>
							<p id="95770623-6c81-41f0-886c-fd4619ebb3bb" class="">
								Google Analytics and Amplitude
							</p>
						</li>
					</ul>
					<ul id="0027c97b-62d1-4c57-85ed-684bca7f44aa" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Website Performance Monitoring</strong>
							<p id="ce740ac7-2611-4423-8998-0ca91287bd55" class="">Sentry</p>
						</li>
					</ul>
					<ul id="43d70fb9-a885-41f8-850c-3f278377ac70" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Website Testing</strong>
							<p id="62b5bc1e-9644-4543-a153-4356714e1194" class="">
								Google Play Console and TestFlight
							</p>
						</li>
					</ul>
					<ul id="1ac36eca-e959-4ae0-a9cc-1f60811ab68d" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Identity Verification</strong>
							<p id="968ed138-2289-447b-b012-f1ded6c3c6b5" class="">
								IDAnalyzer
							</p>
						</li>
					</ul>
					<p id="67c718d6-18d1-4711-8f47-ccd5a422cf3c" class="">
						We also may need to share your personal information in the following
						situations:
					</p>
					<ul id="d573e45c-4bea-4f5a-b7f7-af2df823fe03" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Business Transfers.</strong> We may share or transfer your
							information in connection with, or during negotiations of, any
							merger, sale of company assets, financing, or acquisition of all
							or a portion of our business to another company.
						</li>
					</ul>
					<ul id="4b6c2095-aab2-47db-8d07-e9cdec836817" class="bulleted-list">
						<li style="list-style-type: disc">
							<strong>Offer Wall. </strong>Our application(s) may display a
							third-party hosted &quot;offer wall.&quot; Such an offer wall
							allows third-party advertisers to offer virtual currency, gifts,
							or other items to users in return for the acceptance and
							completion of an advertisement offer. Such an offer wall may
							appear in our application(s) and be displayed to you based on
							certain data, such as your geographic area or demographic
							information. When you click on an offer wall, you will be brought
							to an external website belonging to other persons and will leave
							our application(s). A unique identifier, such as your user ID,
							will be shared with the offer wall provider in order to prevent
							fraud and properly credit your account with the relevant reward.
						</li>
					</ul>
					<h2 id="do-we-use-cookies-tracking" class="">
						<strong
							>5. Do We Use Cookies And Other Tracking Technologies?</strong
						>
					</h2>
					<p id="8bd1ab8f-30a8-40eb-b85e-84aba30dd1f6" class="">
						<em><strong>In Short:</strong></em
						><em
							> We may use cookies and other tracking technologies to collect
							and store your information.</em
						>
					</p>
					<p id="bf5fb2e8-00b3-4745-b6f2-11f84cabd86e" class="">
						We may use cookies and similar tracking technologies (like web
						beacons and pixels) to access or store information. Specific
						information about how we use such technologies and how you can
						refuse certain cookies is set out in our Cookie Notice:
						<a href="https://www.morpher.com/cookie-policy"
							>https://www.morpher.com/cookie-policy</a
						>.
					</p>
					<h2 id="how-handle-social-logins" class="">
						<strong>6. How Do We Handle Your Social Logins?</strong>
					</h2>
					<p id="e03e9383-1ade-49a2-8523-a491f100396f" class="">
						<em><strong>In Short: </strong></em
						><em
							>If you choose to register or log in to our Services using a
							social media account, we may have access to certain information
							about you.</em
						>
					</p>
					<p id="32019dc3-866f-436b-b8af-4975441449d8" class="">
						Our Services offer you the ability to register and log in using your
						third-party social media account details (like your Facebook or
						Twitter logins). Where you choose to do this, we will receive
						certain profile information about you from your social media
						provider. The profile information we receive may vary depending on
						the social media provider concerned, but will often include your
						name, email address, friends list, and profile picture, as well as
						other information you choose to make public on such a social media
						platform.
					</p>
					<p id="bc9946bd-e179-40e5-ad4f-86e22a977d50" class="">
						We will use the information we receive only for the purposes that
						are described in this privacy notice or that are otherwise made
						clear to you on the relevant Services. Please note that we do not
						control, and are not responsible for, other uses of your personal
						information by your third-party social media provider. We recommend
						that you review their privacy notice to understand how they collect,
						use, and share your personal information, and how you can set your
						privacy preferences on their sites and apps.
					</p>
					<h2 id="how-long-keep-information" class="">
						<strong>7. How Long Do We Keep Your Information?</strong>
					</h2>
					<p id="990380be-3522-489f-ad27-85f349716fee" class="">
						<em><strong>In Short: </strong></em
						><em
							>We keep your information for as long as necessary to fulfill the
							purposes outlined in this privacy notice unless otherwise required
							by law.</em
						>
					</p>
					<p id="90cffe52-5c2f-47f6-a970-332a200f5f03" class="">
						We will only keep your personal information for as long as it is
						necessary for the purposes set out in this privacy notice, unless a
						longer retention period is required or permitted by law (such as
						tax, accounting, or other legal requirements). No purpose in this
						notice will require us keeping your personal information for longer
						than six (6) months past the termination of the user&#x27;s account.
					</p>
					<p id="0437f770-29e8-4fef-b932-782329cfafff" class="">
						When we have no ongoing legitimate business need to process your
						personal information, we will either delete or anonymize such
						information, or, if this is not possible (for example, because your
						personal information has been stored in backup archives), then we
						will securely store your personal information and isolate it from
						any further processing until deletion is possible.
					</p>
					<h2 id="how-keep-information-safe" class="">
						<strong>8. How Do We Keep Your Information Safe?</strong>
					</h2>
					<p id="6032fd18-9e98-4902-b93d-f84b12deac7f" class="">
						<em><strong>In Short: </strong></em
						><em
							>We aim to protect your personal information through a system of
							organizational and technical security measures.</em
						>
					</p>
					<p id="5bd9f1b2-b1ae-447a-a6bc-3dd63dbdce17" class="">
						We have implemented appropriate and reasonable technical and
						organizational security measures designed to protect the security of
						any personal information we process. However, despite our safeguards
						and efforts to secure your information, no electronic transmission
						over the Internet or information storage technology can be
						guaranteed to be 100% secure, so we cannot promise or guarantee that
						hackers, cybercriminals, or other unauthorized third parties will
						not be able to defeat our security and improperly collect, access,
						steal, or modify your information. Although we will do our best to
						protect your personal information, transmission of personal
						information to and from our Services is at your own risk. You should
						only access the Services within a secure environment.
					</p>
					<h2 id="do-we-collect-information-from-minors" class="">
						<strong>9. Do We Collect Information From Minors?</strong>
					</h2>
					<p id="d8052064-918e-4a0e-84af-eaef2be6d3ac" class="">
						<em><strong>In Short:</strong></em
						><em
							> We do not knowingly collect data from or market to children
							under 18 years of age.</em
						>
					</p>
					<p id="c871841a-9c3c-4590-b038-d14aee511b5b" class="">
						We do not knowingly solicit data from or market to children under 18
						years of age. By using the Services, you represent that you are at
						least 18 or that you are the parent or guardian of such a minor and
						consent to such minor dependent’s use of the Services. If we learn
						that personal information from users less than 18 years of age has
						been collected, we will deactivate the account and take reasonable
						measures to promptly delete such data from our records. If you
						become aware of any data we may have collected from children under
						age 18, please contact us at privacy@morpher.com.
					</p>
					<h2 id="what-are-your-privacy-rights" class="">
						<strong>10. What Are Your Privacy Rights?</strong>
					</h2>
					<p id="a5a413a0-b348-4ec9-8561-e028083a6927" class="">
						<em><strong>In Short:</strong></em
						><em
							> In some regions, such as the European Economic Area (EEA),
							United Kingdom (UK), and Canada, you have rights that allow you
							greater access to and control over your personal information. You
							may review, change, or terminate your account at any time.</em
						>
					</p>
					<p id="70d0a0b0-164e-4ef7-aa07-651df65c6617" class="">
						In some regions (like the EEA, UK, and Canada), you have certain
						rights under applicable data protection laws. These may include the
						right (i) to request access and obtain a copy of your personal
						information, (ii) to request rectification or erasure; (iii) to
						restrict the processing of your personal information; and (iv) if
						applicable, to data portability. In certain circumstances, you may
						also have the right to object to the processing of your personal
						information. You can make such a request by contacting us by using
						the contact details provided in the section &quot;<a
							href="#contact-about-this-notice"
							>How Can You Contact Us About This Notice</a
						>&quot; below.
					</p>
					<p id="61f14e17-0d45-4af4-b719-1eefeca9b0ff" class="">
						We will consider and act upon any request in accordance with
						applicable data protection laws.
					</p>
					<p id="ff87bfe6-5939-4385-915c-f89a4a7e4c8c" class="">
						If you are located in the EEA or UK and you believe we are
						unlawfully processing your personal information, you also have the
						right to complain to your local data protection supervisory
						authority. You can find their contact details here:
						<a
							href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
							>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a
						>.
					</p>
					<p id="7f41c75a-2df8-4e44-acf7-0e9f49b0b39f" class="">
						If you are located in Switzerland, the contact details for the data
						protection authorities are available here:
						<a href="https://www.edoeb.admin.ch/edoeb/en/home.html"
							>https://www.edoeb.admin.ch/edoeb/en/home.html</a
						>.
					</p>
					<p id="db18c62c-77d1-476a-8115-dfef04fe5067" class="">
						<span style="border-bottom: 0.05em solid"
							><strong>Withdrawing your consent:</strong></span
						>
						If we are relying on your consent to process your personal
						information, which may be express and/or implied consent depending
						on the applicable law, you have the right to withdraw your consent
						at any time. You can withdraw your consent at any time by contacting
						us by using the contact details provided in the section &quot;<a
							href="#contact-about-this-notice"
							>How Can You Contact Us About This Notice</a
						>&quot; below or updating your preferences.
					</p>
					<p id="42b80c79-7031-4e69-88b9-10d62a2aba58" class="">
						However, please note that this will not affect the lawfulness of the
						processing before its withdrawal nor, when applicable law allows,
						will it affect the processing of your personal information conducted
						in reliance on lawful processing grounds other than consent.
					</p>
					<p id="32a1b83c-9199-453f-8450-5894bf1e2e80" class="">
						<span style="border-bottom: 0.05em solid"
							><strong
								>Opting out of marketing and promotional communications:</strong
							></span
						><span style="border-bottom: 0.05em solid"><strong> </strong></span
						>You can unsubscribe from our marketing and promotional
						communications at any time by clicking on the unsubscribe link in
						the emails that we send, or by contacting us using the details
						provided in the section &quot;<a href="#contact-about-this-notice"
							>How Can You Contact Us About This Notice</a
						>&quot; below. You will then be removed from the marketing lists.
						However, we may still communicate with you — for example, to send
						you service-related messages that are necessary for the
						administration and use of your account, to respond to service
						requests, or for other non-marketing purposes.
					</p>
					<p id="b4f7bff1-f182-4bc6-babd-35269d8e1470" class="">
						<strong>Account Information</strong>
					</p>
					<p id="f3e9ab2e-d6b1-4aa0-8519-646e7303da2d" class="">
						If you would at any time like to review or change the information in
						your account or terminate your account, you can:
					</p>
					<ul id="49e7c626-2352-41da-afd8-0776224c4ff4" class="bulleted-list">
						<li style="list-style-type: disc">
							Log in to your account settings and update your user account.
						</li>
					</ul>
					<p id="baf84cb3-b180-427e-8810-6b814ee04e2b" class="">
						Upon your request to terminate your account, we will deactivate or
						delete your account and information from our active databases.
						However, we may retain some information in our files to prevent
						fraud, troubleshoot problems, assist with any investigations,
						enforce our legal terms and/or comply with applicable legal
						requirements.
					</p>
					<p id="a2778dba-152a-4be8-b8f8-e9e9aa3c3f84" class="">
						<span style="border-bottom: 0.05em solid"
							><strong>Cookies and similar technologies:</strong></span
						>
						Most Web browsers are set to accept cookies by default. If you
						prefer, you can usually choose to set your browser to remove cookies
						and to reject cookies. If you choose to remove cookies or reject
						cookies, this could affect certain features or services of our
						Services. To opt out of interest-based advertising by advertisers on
						our Services visit
						<a href="http://www.aboutads.info/choices/"
							>http://www.aboutads.info/choices/</a
						>. For further information, please see our Cookie Notice:
						<a href="https://www.morpher.com/cookie-policy"
							>https://www.morpher.com/cookie-policy</a
						>.
					</p>
					<p id="813dae73-ee3b-471d-9a96-6c010a2d103a" class="">
						If you have questions or comments about your privacy rights, you may
						email us at privacy@morpher.com.
					</p>
					<h2 id="controls-for-do-not-track-features" class="">
						<strong>11. Controls For Do-Not-Track Features</strong>
					</h2>
					<p id="a168bf1c-b986-4be0-a664-4c6467a23313" class="">
						Most web browsers and some mobile operating systems and mobile
						applications include a Do-Not-Track (&quot;DNT&quot;) feature or
						setting you can activate to signal your privacy preference not to
						have data about your online browsing activities monitored and
						collected. At this stage no uniform technology standard for
						recognizing and implementing DNT signals has been finalized. As
						such, we do not currently respond to DNT browser signals or any
						other mechanism that automatically communicates your choice not to
						be tracked online. If a standard for online tracking is adopted that
						we must follow in the future, we will inform you about that practice
						in a revised version of this privacy notice.
					</p>
					<h2 id="california-specific-rights" class="">
						<strong
							>12. Do California Residents Have Specific Privacy Rights?</strong
						>
					</h2>
					<p id="5f9edd7c-79e1-4574-9820-4560ed971d13" class="">
						<em><strong>In Short: </strong></em
						><em
							>Yes, if you are a resident of California, you are granted
							specific rights regarding access to your personal information.</em
						>
					</p>
					<p id="783ba81c-cf54-4268-8c95-e243c6626d0d" class="">
						California Civil Code Section 1798.83, also known as the &quot;Shine
						The Light&quot; law, permits our users who are California residents
						to request and obtain from us, once a year and free of charge,
						information about categories of personal information (if any) we
						disclosed to third parties for direct marketing purposes and the
						names and addresses of all third parties with which we shared
						personal information in the immediately preceding calendar year. If
						you are a California resident and would like to make such a request,
						please submit your request in writing to us using the contact
						information provided below.
					</p>
					<p id="668392b6-9e75-421e-b0bc-c93009abc248" class="">
						If you are under 18 years of age, reside in California, and have a
						registered account with Services, you have the right to request
						removal of unwanted data that you publicly post on the Services. To
						request removal of such data, please contact us using the contact
						information provided below and include the email address associated
						with your account and a statement that you reside in California. We
						will make sure the data is not publicly displayed on the Services,
						but please be aware that the data may not be completely or
						comprehensively removed from all our systems (e.g., backups, etc.).
					</p>
					<h2 id="do-we-make-updates-to-this-notice" class="">
						<strong>13. Do We Make Updates To This Notice?</strong>
					</h2>
					<p id="66f92ec3-2531-4c73-a453-3c63dee5a3c1" class="">
						<em><strong>In Short: </strong></em
						><em
							>Yes, we will update this notice as necessary to stay compliant
							with relevant laws.</em
						>
					</p>
					<p id="9dbb466b-062c-48ba-b666-d590c88a5383" class="">
						We may update this privacy notice from time to time. The updated
						version will be indicated by an updated &quot;Revised&quot; date and
						the updated version will be effective as soon as it is accessible.
						If we make material changes to this privacy notice, we may notify
						you either by prominently posting a notice of such changes or by
						directly sending you a notification. We encourage you to review this
						privacy notice frequently to be informed of how we are protecting
						your information.
					</p>
					<h2 id="contact-about-this-notice" class="">
						<strong>14. How Can You Contact Us About This Notice?</strong>
					</h2>
					<p id="e497d821-cacf-49c9-a0f8-30f3f7baab57" class="">
						If you have questions or comments about this notice, you may contact
						our Data Protection Officer (DPO), Martin Froehler, by email
						at privacy@morpher.com, or by post to:
					</p>
					<p id="6830f26f-2dda-48ae-912c-04d7e0ee9825" class="">
						Morpher Labs GmbH Martin Froehler Heinestraße 21/4 Vienna, 1020
						Austria
					</p>
					<p id="daf015ec-7e37-4aff-adbc-d70c067da678" class="">
						If you are a resident in the European Economic Area, the &quot;data
						controller&quot; of your personal information is Morpher Labs GmbH.
						Morpher Labs GmbH has appointed Martin Froehler to be its
						representative in the EEA. You can contact them directly regarding
						the processing of your information by Morpher Labs GmbH, by email at
						privacy@morpher.com, or by post to:
					</p>
					<p id="8895f6a2-2121-43be-b8cd-f2e7094e486f" class="">
						Heinestraße 21/4 Vienna, 1020 Austria
					</p>
					<h2 id="review-update-delete-data-collected" class="">
						<strong
							>15. How Can You Review, Update, Or Delete The Data We Collect
							From You?</strong
						>
					</h2>
					<p id="979e6353-ab71-431c-8bb4-75fa441ac798" class="">
						Based on the applicable laws of your country, you may have the right
						to request access to the personal information we collect from you,
						change that information, or delete it. To request to review, update,
						or delete your personal information, please
						<a href="mailto:contact@help.morpher.com">contact support</a>.
					</p>
				</div>
			</div>
		</div>
		<br />
		<Footer :disclaimerContent="0" />
	</section>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue";
import Footer from "@/components/partials/Footer";

export default {
	name: "PrivacyPolicyMobile",
	components: {
		Navbar,
		Footer,
	},
	mounted() {
		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
	},
	head() {
		return {
			title: {
				inner: this.$t("meta.PRIVACY_POLICY_TITLE"),
			},
			meta: [
				{
					name: "description",
					content: this.$t("meta.PRIVACY_POLICY_DESC"),
					id: "desc",
				},
				{
					property: "og:title",
					content: this.$t("meta.PRIVACY_POLICY_TITLE"),
					id: "og-title",
				},
				{
					property: "og:description",
					content: this.$t("meta.PRIVACY_POLICY_DESC"),
					id: "og-description",
				},
				{ property: "og:image", content: "", id: "og-image" },
				{ property: "twitter:card", content: "", id: "twitter-card" },
				{ property: "twitter:image", content: "", id: "twitter-image" },
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
	-webkit-print-color-adjust: exact;
}
* {
	box-sizing: border-box;
	-webkit-print-color-adjust: exact;
}

html,
body {
	margin: 0;
	padding: 0;
}
@media only screen {
	body {
		margin: 2em auto;
		max-width: 900px;
		color: rgb(55, 53, 47);
	}
}

body {
	line-height: 1.5;
	white-space: pre-wrap;
}

a,
a.visited {
	color: inherit;
	text-decoration: underline;
}

.pdf-relative-link-path {
	font-size: 80%;
	color: #444;
}

h1,
h2,
h3 {
	letter-spacing: -0.01em;
	line-height: 1.2;
	font-weight: 600;
	margin-bottom: 0;
}

.page-title {
	font-size: 2.5rem;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 0.75em;
}

h1 {
	font-size: 1.875rem;
	margin-top: 1.875rem;
}

h2 {
	font-size: 1.5rem;
	margin-top: 1.5rem;
}

h3 {
	font-size: 1.25rem;
	margin-top: 1.25rem;
}

.source {
	border: 1px solid #ddd;
	border-radius: 3px;
	padding: 1.5em;
	word-break: break-all;
}

.callout {
	border-radius: 3px;
	padding: 1rem;
}

figure {
	margin: 1.25em 0;
	page-break-inside: avoid;
}

figcaption {
	opacity: 0.5;
	font-size: 85%;
	margin-top: 0.5em;
}

mark {
	background-color: transparent;
}

.indented {
	padding-left: 1.5em;
}

hr {
	background: transparent;
	display: block;
	width: 100%;
	height: 1px;
	visibility: visible;
	border: none;
	border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
	max-width: 100%;
}

@media only print {
	img {
		max-height: 100vh;
		object-fit: contain;
	}
}

@page {
	margin: 1in;
}

.collection-content {
	font-size: 0.875rem;
}

.column-list {
	display: flex;
	justify-content: space-between;
}

.column {
	padding: 0 1em;
}

.column:first-child {
	padding-left: 0;
}

.column:last-child {
	padding-right: 0;
}

.table_of_contents-item {
	display: block;
	font-size: 0.875rem;
	line-height: 1.5;
	padding: 0.125rem;
}

.table_of_contents-indent-1 {
	margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
	margin-left: 3rem;
}

.table_of_contents-indent-3 {
	margin-left: 4.5rem;
}

.table_of_contents-link {
	text-decoration: none;
	opacity: 0.7;
	border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
	border: 1px solid rgba(55, 53, 47, 0.09);
	border-collapse: collapse;
}

table {
	border-left: none;
	border-right: none;
}

th,
td {
	font-weight: normal;
	padding: 0.25em 0.5em;
	line-height: 1.5;
	min-height: 1.5em;
	text-align: left;
}

th {
	color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
	margin: 0;
	margin-block-start: 0.6em;
	margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
	margin-block-start: 0.6em;
}

ul > li {
	list-style: disc;
}

ul.to-do-list {
	text-indent: -1.7em;
}

ul.to-do-list > li {
	list-style: none;
}

.to-do-children-checked {
	text-decoration: line-through;
	opacity: 0.375;
}

ul.toggle > li {
	list-style: none;
}

ul {
	padding-inline-start: 1.7em;
}

ul > li {
	padding-left: 0.1em;
}

ol {
	padding-inline-start: 1.6em;
}

ol > li {
	padding-left: 0.2em;
}

.mono ol {
	padding-inline-start: 2em;
}

.mono ol > li {
	text-indent: -0.4em;
}

.toggle {
	padding-inline-start: 0em;
	list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
	padding-left: 1.7em;
}

.toggle > li > details > summary {
	margin-left: -1.1em;
}

.selected-value {
	display: inline-block;
	padding: 0 0.5em;
	background: rgba(206, 205, 202, 0.5);
	border-radius: 3px;
	margin-right: 0.5em;
	margin-top: 0.3em;
	margin-bottom: 0.3em;
	white-space: nowrap;
}

.collection-title {
	display: inline-block;
	margin-right: 1em;
}

.simple-table {
	margin-top: 1em;
	font-size: 0.875rem;
	empty-cells: show;
}
.simple-table td {
	height: 29px;
	min-width: 120px;
}

.simple-table th {
	height: 29px;
	min-width: 120px;
}

.simple-table-header-color {
	background: rgb(247, 246, 243);
	color: black;
}
.simple-table-header {
	font-weight: 500;
}

time {
	opacity: 0.5;
}

.icon {
	display: inline-block;
	max-width: 1.2em;
	max-height: 1.2em;
	text-decoration: none;
	vertical-align: text-bottom;
	margin-right: 0.5em;
}

img.icon {
	border-radius: 3px;
}

.user-icon {
	width: 1.5em;
	height: 1.5em;
	border-radius: 100%;
	margin-right: 0.5rem;
}

.user-icon-inner {
	font-size: 0.8em;
}

.text-icon {
	border: 1px solid #000;
	text-align: center;
}

.page-cover-image {
	display: block;
	object-fit: cover;
	width: 100%;
	max-height: 30vh;
}

.page-header-icon {
	font-size: 3rem;
	margin-bottom: 1rem;
}

.page-header-icon-with-cover {
	margin-top: -0.72em;
	margin-left: 0.07em;
}

.page-header-icon img {
	border-radius: 3px;
}

.link-to-page {
	margin: 1em 0;
	padding: 0;
	border: none;
	font-weight: 500;
}

p > .user {
	opacity: 0.5;
}

td > .user,
td > time {
	white-space: nowrap;
}

input[type="checkbox"] {
	transform: scale(1.5);
	margin-right: 0.6em;
	vertical-align: middle;
}

p {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.image {
	border: none;
	margin: 1.5em 0;
	padding: 0;
	border-radius: 0;
	text-align: center;
}

.code,
code {
	background: rgba(135, 131, 120, 0.15);
	border-radius: 3px;
	padding: 0.2em 0.4em;
	border-radius: 3px;
	font-size: 85%;
	tab-size: 2;
}

code {
	color: #eb5757;
}

.code {
	padding: 1.5em 1em;
}

.code-wrap {
	white-space: pre-wrap;
	word-break: break-all;
}

.code > code {
	background: none;
	padding: 0;
	font-size: 100%;
	color: inherit;
}

blockquote {
	font-size: 1.25em;
	margin: 1em 0;
	padding-left: 1em;
	border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
	text-decoration: none;
	max-height: 8em;
	padding: 0;
	display: flex;
	width: 100%;
	align-items: stretch;
}

.bookmark-title {
	font-size: 0.85em;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 1.75em;
	white-space: nowrap;
}

.bookmark-text {
	display: flex;
	flex-direction: column;
}

.bookmark-info {
	flex: 4 1 180px;
	padding: 12px 14px 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.bookmark-image {
	width: 33%;
	flex: 1 1 180px;
	display: block;
	position: relative;
	object-fit: cover;
	border-radius: 1px;
}

.bookmark-description {
	color: rgba(55, 53, 47, 0.6);
	font-size: 0.75em;
	overflow: hidden;
	max-height: 4.5em;
	word-break: break-word;
}

.bookmark-href {
	font-size: 0.75em;
	margin-top: 0.25em;
}

.sans {
	font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
		Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
		"Segoe UI Symbol";
}
.code {
	font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono",
		Courier, monospace;
}
.serif {
	font-family: Lyon-Text, Georgia, ui-serif, serif;
}
.mono {
	font-family: iawriter-mono, Nitti, Menlo, Courier, monospace;
}
.pdf .sans {
	font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
		"Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
		"Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
		"Noto Sans CJK JP";
}
.pdf:lang(zh-CN) .sans {
	font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
		"Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
		"Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
		"Noto Sans CJK SC";
}
.pdf:lang(zh-TW) .sans {
	font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
		"Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
		"Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
		"Noto Sans CJK TC";
}
.pdf:lang(ko-KR) .sans {
	font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
		"Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
		"Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
		"Noto Sans CJK KR";
}
.pdf .code {
	font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
		"Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
		"Noto Sans Mono CJK JP";
}
.pdf:lang(zh-CN) .code {
	font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
		"Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
		"Noto Sans Mono CJK SC";
}
.pdf:lang(zh-TW) .code {
	font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
		"Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
		"Noto Sans Mono CJK TC";
}
.pdf:lang(ko-KR) .code {
	font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
		"Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
		"Noto Sans Mono CJK KR";
}
.pdf .serif {
	font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
		"Noto Color Emoji", "Noto Serif CJK JP";
}
.pdf:lang(zh-CN) .serif {
	font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
		"Noto Color Emoji", "Noto Serif CJK SC";
}
.pdf:lang(zh-TW) .serif {
	font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
		"Noto Color Emoji", "Noto Serif CJK TC";
}
.pdf:lang(ko-KR) .serif {
	font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
		"Noto Color Emoji", "Noto Serif CJK KR";
}
.pdf .mono {
	font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
		"Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK JP";
}
.pdf:lang(zh-CN) .mono {
	font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
		"Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK SC";
}
.pdf:lang(zh-TW) .mono {
	font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
		"Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK TC";
}
.pdf:lang(ko-KR) .mono {
	font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
		"Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK KR";
}
.highlight-default {
	color: rgba(55, 53, 47, 1);
}
.highlight-gray {
	color: rgba(120, 119, 116, 1);
	fill: rgba(120, 119, 116, 1);
}
.highlight-brown {
	color: rgba(159, 107, 83, 1);
	fill: rgba(159, 107, 83, 1);
}
.highlight-orange {
	color: rgba(217, 115, 13, 1);
	fill: rgba(217, 115, 13, 1);
}
.highlight-yellow {
	color: rgba(203, 145, 47, 1);
	fill: rgba(203, 145, 47, 1);
}
.highlight-teal {
	color: rgba(68, 131, 97, 1);
	fill: rgba(68, 131, 97, 1);
}
.highlight-blue {
	color: rgba(51, 126, 169, 1);
	fill: rgba(51, 126, 169, 1);
}
.highlight-purple {
	color: rgba(144, 101, 176, 1);
	fill: rgba(144, 101, 176, 1);
}
.highlight-pink {
	color: rgba(193, 76, 138, 1);
	fill: rgba(193, 76, 138, 1);
}
.highlight-red {
	color: rgba(212, 76, 71, 1);
	fill: rgba(212, 76, 71, 1);
}
.highlight-gray_background {
	background: rgba(241, 241, 239, 1);
}
.highlight-brown_background {
	background: rgba(244, 238, 238, 1);
}
.highlight-orange_background {
	background: rgba(251, 236, 221, 1);
}
.highlight-yellow_background {
	background: rgba(251, 243, 219, 1);
}
.highlight-teal_background {
	background: rgba(237, 243, 236, 1);
}
.highlight-blue_background {
	background: rgba(231, 243, 248, 1);
}
.highlight-purple_background {
	background: rgba(244, 240, 247, 0.8);
}
.highlight-pink_background {
	background: rgba(249, 238, 243, 0.8);
}
.highlight-red_background {
	background: rgba(253, 235, 236, 1);
}
.block-color-default {
	color: inherit;
	fill: inherit;
}
.block-color-gray {
	color: rgba(120, 119, 116, 1);
	fill: rgba(120, 119, 116, 1);
}
.block-color-brown {
	color: rgba(159, 107, 83, 1);
	fill: rgba(159, 107, 83, 1);
}
.block-color-orange {
	color: rgba(217, 115, 13, 1);
	fill: rgba(217, 115, 13, 1);
}
.block-color-yellow {
	color: rgba(203, 145, 47, 1);
	fill: rgba(203, 145, 47, 1);
}
.block-color-teal {
	color: rgba(68, 131, 97, 1);
	fill: rgba(68, 131, 97, 1);
}
.block-color-blue {
	color: rgba(51, 126, 169, 1);
	fill: rgba(51, 126, 169, 1);
}
.block-color-purple {
	color: rgba(144, 101, 176, 1);
	fill: rgba(144, 101, 176, 1);
}
.block-color-pink {
	color: rgba(193, 76, 138, 1);
	fill: rgba(193, 76, 138, 1);
}
.block-color-red {
	color: rgba(212, 76, 71, 1);
	fill: rgba(212, 76, 71, 1);
}
.block-color-gray_background {
	background: rgba(241, 241, 239, 1);
}
.block-color-brown_background {
	background: rgba(244, 238, 238, 1);
}
.block-color-orange_background {
	background: rgba(251, 236, 221, 1);
}
.block-color-yellow_background {
	background: rgba(251, 243, 219, 1);
}
.block-color-teal_background {
	background: rgba(237, 243, 236, 1);
}
.block-color-blue_background {
	background: rgba(231, 243, 248, 1);
}
.block-color-purple_background {
	background: rgba(244, 240, 247, 0.8);
}
.block-color-pink_background {
	background: rgba(249, 238, 243, 0.8);
}
.block-color-red_background {
	background: rgba(253, 235, 236, 1);
}
.select-value-color-pink {
	background-color: rgba(245, 224, 233, 1);
}
.select-value-color-purple {
	background-color: rgba(232, 222, 238, 1);
}
.select-value-color-green {
	background-color: rgba(219, 237, 219, 1);
}
.select-value-color-gray {
	background-color: rgba(227, 226, 224, 1);
}
.select-value-color-opaquegray {
	background-color: rgba(255, 255, 255, 0.0375);
}
.select-value-color-orange {
	background-color: rgba(250, 222, 201, 1);
}
.select-value-color-brown {
	background-color: rgba(238, 224, 218, 1);
}
.select-value-color-red {
	background-color: rgba(255, 226, 221, 1);
}
.select-value-color-yellow {
	background-color: rgba(253, 236, 200, 1);
}
.select-value-color-blue {
	background-color: rgba(211, 229, 239, 1);
}

.checkbox {
	display: inline-flex;
	vertical-align: text-bottom;
	width: 16;
	height: 16;
	background-size: 16px;
	margin-left: 2px;
	margin-right: 5px;
}

.checkbox-on {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

.checkbox-off {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}

@media screen and (max-width: 768px) {
	.page-body {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
}
</style>
